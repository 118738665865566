import React from 'react'
import aboutimage from '../images/about.png'

function About() {
  return (
    <div id='about'>
        <div className='about-image'>
            <img src={aboutimage} alt="" />
        </div>
        <div className='about-text'>
            <h1>LEARN MORE ABOUT US</h1>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores, aut dolorum fugit cum impedit odio soluta harum quam assumenda adipisci fuga voluptatem, magni officia nobis. Optio amet, mollitia commodi nisi ipsa blanditiis autem perferendis ullam quia dolore nostrum hic sequi, officia odit. Veritatis veniam vero sit aliquid saepe fugit dignissimos.</p>
            <button>READ MORE</button>
        </div>
      
    </div>
  )
}

export default About
