import React from 'react'

function Offer() {
  return (
    <div id='presentaion'>
      <div className='pr-heading'>
        <h1>A BIG <span>OFFER</span>FOR THIS SUMMER</h1>
        <p className='details'>Lorem ipsum dolor sit, amet consectetur adipisicing elit.
        </p>
        <div className='pr-btn'>
            <a href="#" className='pr-btn'>JOIN NOW</a>
        </div>
      </div>
    </div>
  )
}

export default Offer
